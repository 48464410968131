Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.goalsEndPoint='bx_block_custom_forms/substance_related_goals'
exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.categoryShowPoint="bx_block_categories/show_user_categories"
exports.categoryShowPointAll="bx_block_custom_forms/substance_goal_responses/categories_list"
exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";


exports.createFormAPiEndPoint = "bx_block_custom_forms/custom_forms";
exports.token="eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjg2MzE3NzU3LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.qtajs3wt_9FozBlJjL8iOYodM4KuZ0dVrsQbMYmH9798DOv21rRFy8FMgMlDzKDaKg7oZryerKM8ihX1x59fog";
exports.formAPiMethod = "POST";
exports.updateFormAPiMethod="PUT";
exports.fetchFormDataMethod="GET";
exports.formApiContentType = "multipart/form-data";
exports.errorPasswordNotValid = "Not valid.";
exports.labelHeader = " Custom Form ";
exports.btnTxtSubmit = "Submit";
exports.labelOr = "OR";
exports.placeHolderFirstName = "First Name";
exports.placeHolderLastName = "Last Name";
exports.placeHolderPhoneNo = "Phone Number";
exports.placeHolderOrg = "Organization";
exports.placeHolderTeamName = "Team Name";
exports.placeHolderEmail = "Email";
exports.placeHolderAddress = "Address";
exports.placeHolderCountry = "Country";
exports.placeHolderState = "State";
exports.placeHolderCity="City";

exports.errorEmptyFields = "All fields are required.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Submit";

exports.emailRegex=/[a-zA-Z0-9#$%&\*\+-/=\?\_`|~]*[a-zA-Z0-9#$%&\*\+/=\?\_`|~]@/;
exports.UppersAPiEndPoints = "bx_block_custom_forms/substance_goal_responses";
exports.SubstanceAPiMethod = "POST";
exports.UppersApiContentType = "application/json";
exports.UppersGetApiendPoint = "bx_block_custom_forms/show_goal_response?category_name=uppers";
exports.SubstanceGetApiMethod = "GET";
exports.TobaccoAPiEndPoints = "bx_block_custom_forms/substance_goal_responses"
exports.TobaccoGetApiendPoint = "bx_block_custom_forms/show_goal_response?category_name=tobaccoOrNicotine";
exports.DownersAPiEndPoints = "bx_block_custom_forms/substance_goal_responses"
exports.DownersGetApiendPoint = "bx_block_custom_forms/show_goal_response?category_name=downers";
// Customizable Area End