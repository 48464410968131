import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  // [key: string]: string;
  user:string;
  description:string;
  submitButtonClick: boolean;
  token:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ContactUsController extends BlockComponent<
  Props,
  S,
  SS
> {

  getCategoryHandleValue:string="";
  postSUSCategoryApiCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      password: "",
      user:"",
      description:"",
      token:'',
      submitButtonClick: false,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

handleContactUsClose = () => {
  let data = localStorage.getItem("ContactUs") || "{}";
  this.props.navigation.navigate(data);
  localStorage.removeItem("ContactUs");
}
/* istanbul ignore next */
handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = e.target;

  this.setState((prevState) => ({
    ...prevState,
    [name]: value,
  } as Pick<S, keyof S>));
};

/* istanbul ignore next */
handleSubmit = () =>{
  this.setState({ submitButtonClick: true });
  console.log('qwert')
  const formData = new FormData()
  formData.append("contact[name]",this.state.user)
  formData.append("contact[description]",this.state.description)
  const header = {
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.postSUSCategoryApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.contactUsEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage), 
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage), 
    formData
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.loginAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

}

  // Customizable Area End