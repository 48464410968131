import React from "react";

// Customizable Area Start
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
} from "@mui/material";

import {
  topBannerImg,
  bottomBannerImg,
  rightImage,
  notation,
  arrow,
  rightImage2,
} from "./assets";
// Customizable Area End

// Customizable Area Start
import ContactUsController, {
  Props,
} from "./ContactUsController.web";

export default class ContactUs extends ContactUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderContactUs = () => {
    const isValidUser = /^[^a-zA-Z]*$/.test(this.state.user);
    const isValidDescription = /^[^a-zA-Z]*$/.test(this.state.description);
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "48px",
              lineHeight: "48px",
              fontWeight: "bold",
              color: "#00A7D1",
              textAlign: "left",
              margin: "4px 0px",
              fontFamily: "Josefin Sans",
              marginTop:'30px'
            }}
          >
            Contact us!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
                placeholder="Username"
                multiline
                onChange={this.handleChange}
                value={this.state.user}
                InputProps={{               
                  sx: {
                    height: "58px",
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    "&:after": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    "&:before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    ".MuiInputBase-input": {
                      pt: "20px",
                    },
                   
                  },
                  disableUnderline: false,
                }}
                name="user"
                inputProps={{
                  style: {
                    fontFamily: "Chromatica-Regular",
                    fontWeight: 400,
                    fontSize: "16px",
                  },
                  sx: {
                    "&::placeholder": {
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#939393",
                      fontFamily: "Chromatica-Regular",
                    },
                  },
                }}
                sx={{
                  lineHeight: "24px",
                  mt: "20px",
                  color: "#939393",
                  mb: "10px",
                  fontSize: "16px",
                  fontWeight: 400,
                  fontFamily: "Chromatica-Regular",
                }}
                rows={1}
                variant="standard"
                fullWidth
              />
        </Grid>
        { 

/* istanbul ignore next */
this.state.submitButtonClick && (
  (this.state.user?.length < 1 || isValidUser) && (
    <p style={{
      color: "#EB3E3E",
      fontFamily: "Chromatica-Regular",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      margin: 0, 
      marginTop: 'opx',
      paddingLeft:'16px'
    }}>
      { 
      /* istanbul ignore next */
        this.state.user?.length < 1 
          ? "Text field is required!" 
          : "Please enter valid text!"
      }
    </p>
  )
)
}
        <Grid item xs={12} sx={{ fontWeight: 400, fontSize: "40px" }}>
<TextField
        placeholder="Write description here.."
        multiline
        name="description"
            value={this.state.description}
            onChange={this.handleChange}
        InputProps={{
          sx: {
            "&:before": {
              borderBottom: "1px solid #E3E3E3",
            },
            "&:after": {
              borderBottom: "1px solid #E3E3E3",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "1px solid #E3E3E3",
            },
            ".MuiInputBase-input": {
              paddingBottom: "5px",
            },
          },
          disableUnderline: false,
        }}
        inputProps={{
          sx: {
            "&::placeholder": {
              color: "#939393",
              fontWeight: 400,
              fontSize: "16px",
              fontFamily: "Chromatica-Regular",
            },      
          },
          style: {
            fontFamily: "Chromatica-Regular",
            fontSize: "16px",
          },
        }}
        rows={5}
        fullWidth
        variant="standard"
        sx={{
          fontSize: "16px",
          fontFamily: "Chromatica-Regular",
          fontWeight: 400,
          mt: "25px",
          lineHeight: "24px",
          color: "#939393",
        }}
      />
        </Grid>
        
        { 
        /* istanbul ignore next */
this.state.submitButtonClick && (
  (this.state.description?.length < 1 || isValidDescription) && (
    <p style={{
      fontFamily: "Chromatica-Regular",
      fontSize: "16px",
      fontWeight: 500,
      color: "#EB3E3E",
      margin: 0, 
      marginTop: '10px',
      lineHeight: "24px",
      paddingLeft:'16px'
    }}>
      { 
      /* istanbul ignore next */
        this.state.description?.length < 1 
          ? "Text field is required!" 
          : "Please enter valid text!"
      }
    </p>
  )
)
}
        <Grid item xs={12}>
          <Button onClick={this.handleSubmit}  data-test-id="submit-btn"
            fullWidth
            variant="contained"
            sx={{
              padding: "10px",
              borderRadius: "12px",
              mt: "10px",
              background: "linear-gradient(91.48deg, #BACA08 0%, #00A7D1 100%)",
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "24px",
              textTransform: "none",
              fontFamily: "Chromatica-Black",
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Grid
      sx={{
         overflowX:'hidden',
        height: "100vh",
        padding: "10px",
        display: "flex",
        gap: "10px",
        backgroundColor: "#F0F0F0",
      }}
        container
      >
        <Grid
          xs={12}
          item
          sx={{
            position: "relative",
            backgroundColor: "white",
            borderRadius: "20px",
          }}
          md={5.92}
        >
          <Box
            sx={{
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              px: 4,
              display: "flex",
            }}
          >
            <Box
              component="img"
              alt="Logo"
              sx={{ position: "absolute", top: 0, left: 0 }}
              src={topBannerImg.default}
            />
            <Box
              component="img"
              alt="Logo"
              sx={{ position: "absolute", bottom: 0, right: 0 }}
              src={bottomBannerImg.default}
            />
              <Box onClick={this.handleContactUsClose} data-test-id="back-btn">
                <Box
                  component="img"
                  src={arrow.default}
                  alt="Logo"
                  sx={{ position: "absolute", top: 50, left: 30 }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    top: 45,
                    left: 52,
                    cursor: "pointer",
                    style: { fontFamily: "Chromatica-Bold" },
                  }}
                >
                  Back
                </Typography>
              </Box>
            <Box
              sx={{
                width: "100%",
                zIndex: 1,
                textAlign: "center",
              }}
            >
{this.renderContactUs()}
            </Box>
          </Box>
        </Grid>

        <Grid
          md={5.94}
          item
          xs={12}
          sx={{
            background: `url(${rightImage2.default})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
           
            justifyContent: "space-between",
            borderRadius: "20px",
            position: "relative",
            padding: "50px",
            backgroundRepeat: "no-repeat",
          }}
          lg={5.97}
        >
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              top: "20px",
              flexDirection: "column",
              color: "#EBFF00",
              fontSize: "48px",
              left: "20px",
              lineHeight: "56px",
              fontWeight: "700",
            }}
          >
            <img
              src={notation.default}
              style={{
                width: "30px",
                marginBottom: "4px",
                marginLeft: "10px",
                marginTop: "25px",
              }}
              alt="notation"
            />
            <span
              style={{
                fontFamily: "Chromatica-Bold",
                marginBottom: "4px",
                marginLeft: "10px",
              }}
            >
              It's about
            </span>
            <span
              style={{
                marginLeft: "10px",
                marginBottom: "4px",
                fontFamily: "Chromatica-Bold",
              }}
            >
              progress, not
            </span>
            <span
              style={{
                fontFamily: "Chromatica-Bold",
                marginBottom: "4px",
                marginLeft: "10px",
              }}
            >
              perfection.
            </span>
          </Box>
          <Box
            sx={{
              bottom: "20px",
              position: "absolute",
              fontSize: "30px",
              fontFamily: "Josefin Sans",
              color: "#ffffff",
              fontWeight: "normal",
              left: "20px",
            }}
          >
            <span
              style={{
                fontFamily: "Josefin Sans",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#EBFF00",
                marginBottom: "4px",
                marginLeft: "10px",
              }}
            >
              Well
            </span>
            Spring
          </Box>
        </Grid>
      </Grid>
      // Customizable Area End
    );
  }

}
// Customizable Area End